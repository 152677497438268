<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                    &gt;
                    <router-link :to="{ name: 'account-search-client', params: { accountId: this.$route.params.accountId } }">Clients</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="client">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Client {{ client.id }}</p>
                    <v-card tile elevation="4" class="pa-0 mt-0">
                        <v-toolbar dense flat color="teal" dark>
                            <!-- TODO: the draft/published feature here from unicorn springs could be repurposed to an active/inactive, or revoke access feature, so user can revoke access for a client without deleting the record (so we can still know the client id in logs etc.) -->
                            <v-toolbar-title>
                                Client
                                <!-- <span v-if="client.is_draft_pending && !client.published">(draft)</span>
                                <span v-if="client.is_draft_pending && client.published">(published)</span> -->
                                <!-- <v-chip v-if="client.is_active" label small class="px-1 ml-2 teal white--text">Active</v-chip>
                                <v-chip v-if="!client.is_active" label small class="px-1 ml-2 teal black--text">Inactive</v-chip> -->
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <!-- <v-btn text small class="px-1 ml-2 teal white--text" @click="publishClient" v-if="client.is_draft_pending && client.is_active">Update</v-btn>
                            <v-btn text small class="px-1 ml-2 teal white--text" @click="publishClient" v-if="client.is_draft_pending && !client.is_active">Publish</v-btn>
                            <v-btn icon color="white" @click="publishClient" v-if="client.is_draft_pending">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width/>
                            </v-btn> -->
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-4">Label</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.label" @input="saveClientLabel" dense/>
                            </p>

                            <p class="text-overline mb-0 mt-8">Security</p>
                            <p>Allowed origin protocols</p>
                            <p class="grey--text text--darken-1 text-caption">By default, clients are required to provide <var>https</var> URLs for redirecting users after a profile request. However, when developing a client application it may be extremely inconvenient to set up <var>https</var>. You can choose to allow <var>http</var> in a development environment. We strongly recommend to <strong>not</strong> enable <var>http</var> in production.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelectMultiple :value="permitOriginProtocolList" :items="clientPermitOriginProtocolChoices" @input="saveClientPermitOriginProtocol" dense/>
                            </p>

                            <!-- TODO: enter flat rate shipping (when using USPS) or set up custom rate shipping integration for FedEx, UPS, etc. based on package size and weight and the carrier... various services exist that help with the computation, or we can do our own query of the current rates and compute for the merchant... -->

                            <!-- <p class="text-overline mb-0 mt-8">Description</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The description is PUBLIC and is shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.description" @input="saveClientDescription" dense/>
                            </p> -->

                            <!-- TODO: move to comment component -->
                            <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The comment is PRIVATE and is NOT shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.comment" @input="saveClientComment" dense/>
                            </p> -->
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewBrandProfileURL" target="_blank">{{ viewBrandProfileURL }}</TextLink>
                            </p> -->
                            <!-- <v-divider/> -->
                            <!-- <p class="text-overline mb-0 mt-8">
                                API Tokens
                                <v-btn icon color="teal" @click="createToken">
                                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                                </v-btn>
                            </p> -->
                            <!-- TODO: replace this with client token list -->
                            <!-- <v-list v-if="Array.isArray(clientTokenList)">
                                <v-list-item v-for="item in clientTokenList" :key="item.id">
                                    <v-list-item-content>
                                        <EditableClientToken :value="item" :client="client" @input="saveClientToken"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
                            <!-- <v-card flat tile color="white">
                                <v-toolbar dense flat color="white">
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="teal">
                                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                                    </v-btn>
                                </v-toolbar>
                            </v-card> -->
                        </v-card-text>
                    </v-card>
                    <!-- <v-dialog v-model="addTokenDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="teal lighten-3">
                            <v-toolbar-title >Add Token</v-toolbar-title>
                        </v-toolbar>
                        <v-form @submit.prevent="createToken" @keyup.enter.native.prevent="createToken" class="px-2">
                            <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newItemLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="teal white--text" @click="createToken" :disabled="!isCreateTokenFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="addTokenDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
// import EditableTextSelect from '@/components/EditableTextSelect.vue';
import EditableTextSelectMultiple from '@/components/EditableTextSelectMultiple.vue';
// import EditableClientToken from '@/components/EditableClientToken.vue';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
        EditableText,
        // EditableTextSelect,
        EditableTextSelectMultiple,
        // EditableClientToken,
    },
    data: () => ({
        account: null,
        client: null,
        clientTokenList: null,
        status: null,
        error: null,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        addTokenDialog: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            clientPermitOriginProtocolChoices: (state) => state.clientPermitOriginProtocolChoices,
            clientPublishedChoices: (state) => state.clientPublishedChoices,
        }),
        isViewReady() {
            return this.account !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreateTokenFormComplete() {
            return false;
        },
        // customerClientLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.etherlink-main.test/brand/libertydns/client?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        permitOriginProtocolList() {
            return this.client.permit?.origin_protocol ?? [];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClient: true });
                const query = { id: this.$route.params.clientId };
                // if (this.$route.query.published) {
                //     query.published = this.$route.query.published; // 'true' or 'false'
                // }
                const response = await this.$client.account(this.$route.params.accountId).client.get(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.client = response;
                    this.loadClientTokenList();
                    // this.checkClient();
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load client', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClient: false });
            }
        },
        // async checkClient() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { checkClient: true });
        //         const query = { id: this.$route.params.clientId };
        //         const response = await this.$client.account(this.$route.params.accountId).client.check(query, { item: 'stripe' });
        //         console.log(`editclient.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.status = response;
        //         } else {
        //             this.status = null;
        //         }
        //     } catch (err) {
        //         console.error('failed to check client status', err);
        //         this.status = null;
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { checkClient: false });
        //     }
        // },
        async loadClientTokenList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClientTokenList: true });
                const query = { client_id: this.$route.params.clientId };
                // if (this.$route.query.published === 'true') {
                //     query.published = 'true'; // this.$route.query.published; // 'true' or 'false'
                // } else {
                //     query.published = 'false';
                // }
                const response = await this.$client.account(this.$route.params.accountId).clientToken.search(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.clientTokenList = response.list;
                } else {
                    this.clientTokenList = [];
                }
                // check if any of the tokens have a draft pending, and enable the publish button for that
                const isTokenDraftPending = this.clientTokenList.reduce((acc, cur) => acc || cur, false);
                if (isTokenDraftPending) {
                    this.$set(this.client, 'is_draft_pending', true);
                }
            } catch (err) {
                console.error('failed to load tokens', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        async editBrandProfileAlias() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editBrandProfileAlias: true });
                const response = await this.$client.account(this.$route.params.accountId).setting.edit({ name: 'brandprofile' }, { content: this.editableBrandProfileAlias });
                console.log(`editBrandProfileAlias: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditBrandProfile = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadSetting();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
                }
            } catch (err) {
                console.error('failed to edit BrandProfile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
            } finally {
                this.$store.commit('loading', { editBrandProfileAlias: false });
            }
        },
        async saveClientAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.account(this.$route.params.accountId).client.edit({ id: this.$route.params.clientId }, { [name]: value });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.client, name, value);
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit client' });
                }
            } catch (err) {
                console.error(`failed to edit client attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit client' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        async saveClientLabel(label) {
            this.saveClientAttr('label', label);
        },
        async saveClientPermitOriginProtocol(list) {
            const permit = this.client.permit ?? {};
            permit.origin_protocol = list;
            await this.saveClientAttr('permit', permit);
        },
        // async saveClientRequireName(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_name = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireEmail(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_email = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireSignup(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_signup = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireBilling(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_billing = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientRequireShipping(value) {
        //     const checkout = this.client.checkout ?? {};
        //     checkout.require_shipping = value;
        //     this.saveClientAttr('checkout', checkout);
        // },
        // async saveClientDescription(description) {
        //     this.saveClientAttr('description', description);
        // },
        // TODO: redo this with new comment API and comment collection by ADDING a comment or EDITING an existing comment or DELETING an existing comment ; but these need to be done just once because comments can apply to any object; so we need a comment component that will show up on this view and user cna tap it to manage comments
        // async saveClientComment(comment) {
        //     this.saveClientAttr('comment', comment);
        // },
        /*
        async publishClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishClient: true });
                const response = await this.$client.account(this.$route.params.accountId).client.publish({ id: this.$route.params.clientId });
                console.log(`publishClient: response ${JSON.stringify(response)}`);
                if (typeof response?.isPublished === 'boolean') {
                    this.$set(this.client, 'is_active', response.isPublished);
                    if (response.isPublished) {
                        this.$set(this.client, 'is_draft_pending', false);
                        for (let i = 0; i < this.clientTokenList.length; i += 1) {
                            const item = this.clientTokenList[i];
                            item.is_active = response.isPublished;
                            item.is_draft_pending = false;
                            this.clientTokenList.splice(i, 1, item);
                        }
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish client' });
                }
            } catch (err) {
                console.error('failed to publish client', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish client' });
            } finally {
                this.$store.commit('loading', { publishClient: false });
            }
        },
        async unpublishClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishClient: true });
                const response = await this.$client.account(this.$route.params.accountId).client.unpublish({ id: this.$route.params.clientId });
                console.log(`publishClient: response ${JSON.stringify(response)}`);
                if (typeof response?.isPublished === 'boolean') {
                    this.$set(this.client, 'is_active', response.isPublished);
                    if (!response.isPublished) {
                        this.$set(this.client, 'is_draft_pending', true);
                    }
                    for (let i = 0; i < this.clientTokenList.length; i += 1) {
                        const item = this.clientTokenList[i];
                        item.is_active = false;
                        item.is_draft_pending = true;
                        this.clientTokenList.splice(i, 1, item);
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish client' });
                }
            } catch (err) {
                console.error('failed to unpublish client', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish client' });
            } finally {
                this.$store.commit('loading', { publishClient: false });
            }
        },
        */
        async createToken() {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.account(this.$route.params.accountId).clientToken.create({
                    client_id: this.$route.params.clientId,
                });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.clientTokenList.push(editedItem);
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
                }
            } catch (err) {
                console.error('failed to edit client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        async saveClientToken(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientToken: true });
                const response = await this.$client.account(this.$route.params.accountId).clientToken.edit({ id: item.id }, item);
                console.log(`saveClientToken: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    const editedItem = { ...item, is_draft_pending: true };
                    const idx = this.clientTokenList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.clientTokenList.splice(idx, 1, editedItem);
                    } else {
                        this.clientTokenList.push(editedItem);
                    }
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save client token' });
                }
            } catch (err) {
                console.error('failed to save client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cresaveate client token' });
            } finally {
                this.$store.commit('loading', { saveClientToken: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadClient();
    },
};
</script>
